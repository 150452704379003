
import { defineComponent, ref, reactive, watch } from "vue";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import qs from "qs";
import axios from "@/api/axios";
import dateFormat from "@/utils/dateFormat";
import Recommend, { RecommnedList } from "@/components/common/Recommend.vue";
import { useRoute } from "vue-router";
//  components
import Loading from "@/components/UI/Loading";
export default defineComponent({
  name: "DocDetail",
  components: {
    Recommend,
    Loading,
  },
  setup() {
    const route = useRoute();
    const id = route.query.docId;
    const library = ref<any>(null);
    const loading = ref(true);
    const isFav = ref(false);
    const recommendList = reactive<RecommnedList[]>([]);

    //  async data
    function getRenderData(id: string) {
      //  async data
      library.value = null;
      loading.value = true;
      recommendList.length = 0;
      axios
        .get("/M/Discover/LibraryDetail/" + id)
        .then((res) => {
          if (res.data.success) {
            const data = res.data.obj;
            library.value = data.library;
            isFav.value = data.isFav;
            data.list.forEach((item: any) => {
              const link = {
                name: "DocDetail",
                query: { docId: item.id },
              };
              recommendList.push({
                id: item.id,
                title: item.docTitle,
                link,
              });
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          loading.value = false;
        });
    }
    getRenderData(id as string);
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    function onCollect(isCollect: boolean) {
      const data = qs.stringify({
        id: (library.value as any).id,
        type: 4,
      });
      axios
        .post("/M/Server/Collection", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            isFav.value = true;
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
             console.log('%c=========','color:blue');
          console.log(err);
          // const message = err.message ? err.message : "未知错误，请联系管理员";
          // Toast({
          //   type: "error",
          //   title: message,
          // });
        });
    }
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        if (to[0].docId && !from[1].valid) {
          getRenderData(to[0].docId as string);
        }
      }
    );
    function download() {
      Modal({
        type: "none",
        title: "温馨提示",
        content: "下载请移步至电脑端：https://www.bzwz.com/",
      });
    }
    return {
      dateFormat,
      library,
      loading,
      loadOption,
      recommendList,
      onCollect,
      isFav,
      download,
    };
  },
});
