<template>
  <main v-if="library">
    <div class="news-title">{{ library.docTitle }}</div>
<!--    <div class="news-info">-->
<!--      <span class="date"> 发布时间： {{ dateFormat(library.createTime) }}</span>-->
<!--    </div>-->
    <div class="topic-author-info">
      <router-link :to="{ name: 'Personal', query: { uid:library.userId } }">
        <img :src="library.user.userImg" class="avatar" alt="" />
      </router-link>
      <div class="author-detail">
        <div class="name">
          <router-link :to="{ name: 'Personal', query: { uid: library.userId } }">
            {{ library.author }}
          </router-link>
        </div>
        <div class="date">
          <router-link :to="{ name: 'Personal', query: { uid: library.createUser } }">
            {{ dateFormat(library.createTime) }}
          </router-link>
        </div>
      </div>
    </div>




    <div class="news-content"><div v-html="library.docContent"></div></div>
    <recommend title="推荐阅读" :list="recommendList"></recommend>

    <footer>
      <ul class="footer-nav">
        <li class="footer-li">
          <a
            href="https://tb.53kf.com/code/client/a7c96e4ceb10e87d6fc792aec24104e05/3"
          >
            <!-- <img src="https://www.bzwz.com/static/m/images/icon52.png" alt="" /> -->
            <img src="@/assets/img/common/icon52.png" alt="" />
            客服
          </a>
        </li>
<!--        <li class="footer-li">-->
<!--          <a-->
<!--            href="javascript:;"-->
<!--            class="collect collect-btn"-->
<!--            data-id="${(library.id)?c}"-->
<!--          >-->
<!--            <img-->
<!--              v-if="isFav"-->
<!--              src="@/assets/img/common/icon53_1.png"-->
<!--              alt=""-->
<!--            />-->
<!--            &lt;!&ndash; src="https://www.bzwz.com/static/m/images/icon53_1.png" &ndash;&gt;-->

<!--            <img-->
<!--              v-else-->
<!--              src="@/assets/img/common/icon53.png"-->
<!--              alt=""-->
<!--              @click="onCollect"-->
<!--            />-->
<!--            &lt;!&ndash; src="https://www.bzwz.com/static/m/images/icon53.png" &ndash;&gt;-->

<!--            收藏-->
<!--          </a>-->
<!--        </li>-->

        <li class="footer-li view-video" @click="download">
          <a href="javascript:;" class="download" download="">下载</a>
        </li>
      </ul>
    </footer>
  </main>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, watch } from "vue";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import qs from "qs";
import axios from "@/api/axios";
import dateFormat from "@/utils/dateFormat";
import Recommend, { RecommnedList } from "@/components/common/Recommend.vue";
import { useRoute } from "vue-router";
//  components
import Loading from "@/components/UI/Loading";
export default defineComponent({
  name: "DocDetail",
  components: {
    Recommend,
    Loading,
  },
  setup() {
    const route = useRoute();
    const id = route.query.docId;
    const library = ref<any>(null);
    const loading = ref(true);
    const isFav = ref(false);
    const recommendList = reactive<RecommnedList[]>([]);

    //  async data
    function getRenderData(id: string) {
      //  async data
      library.value = null;
      loading.value = true;
      recommendList.length = 0;
      axios
        .get("/M/Discover/LibraryDetail/" + id)
        .then((res) => {
          if (res.data.success) {
            const data = res.data.obj;
            library.value = data.library;
            isFav.value = data.isFav;
            data.list.forEach((item: any) => {
              const link = {
                name: "DocDetail",
                query: { docId: item.id },
              };
              recommendList.push({
                id: item.id,
                title: item.docTitle,
                link,
              });
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          loading.value = false;
        });
    }
    getRenderData(id as string);
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    function onCollect(isCollect: boolean) {
      const data = qs.stringify({
        id: (library.value as any).id,
        type: 4,
      });
      axios
        .post("/M/Server/Collection", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            isFav.value = true;
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
             console.log('%c=========','color:blue');
          console.log(err);
          // const message = err.message ? err.message : "未知错误，请联系管理员";
          // Toast({
          //   type: "error",
          //   title: message,
          // });
        });
    }
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        if (to[0].docId && !from[1].valid) {
          getRenderData(to[0].docId as string);
        }
      }
    );
    function download() {
      Modal({
        type: "none",
        title: "温馨提示",
        content: "下载请移步至电脑端：https://www.bzwz.com/",
      });
    }
    return {
      dateFormat,
      library,
      loading,
      loadOption,
      recommendList,
      onCollect,
      isFav,
      download,
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding: 15px 10px;
  padding-bottom: 110px;
  /*text-align: center;*/

}

.news-title {
  color: #444444;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 20px;
}

/*.news-info {*/
/*  color: #999999;*/
/*  font-size: 14px;*/
/*}*/

/*.author,*/
/*.date {*/
/*  margin-right: 20px;*/
/*  display: inline-block;*/
/*  vertical-align: middle;*/
/*}*/

.topic-author-info {
  position: relative;
  padding-left: 49px;
  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
}

.author-detail {
  color: #999999;
  font-size: 14px;
  margin-right: 5px;
  display: inline-block;
  a {
    color: #999999;
    font-size: 14px;
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #20c2d6;
    font-size: 15px;
    a {
      color: #444444;
      font-size: 15px;
    }
  }
  .topic-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 14px;
  }
}


.count {
  margin-right: 0;
}

.news-content {
  width: 355px;

  margin: 0 auto;
  margin-top: 35px;
  padding: 0 10px;
  box-sizing: border-box;
  text-align: justify;
  font-size: 16px;
  color: #666666;
  line-height: 25px;

  border-bottom: 1px solid #eee;
  :deep(p) {
    margin: 1em 0;
    word-break: break-word;
  }
  :deep(a) {
    color: #3498db;
    text-decoration: underline;
  }
  :deep(video) {
    width: 100% !important;
    height: auto !important;
  }
  :deep(table) {
    width: 100% !important;
  }
  :deep(img) {
    width: 100% !important;
    height: auto !important;
  }
  :deep(table) {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
  }
}

footer {
  position: fixed;
  height: 55.988px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #eee;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1px solid #f4f4f4;

  background-color: #fff;
  z-index: 100;
}
footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-li {
  float: left;
  width: 15%;
  text-align: center;
  position: relative;
}
.footer-li img {
  display: block;
  width: 20px;
  height: 20px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -9.75px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #df0024;
}

.footer-nav .active .active-status {
  display: block;
}

.view-video {
  width: 110px;
  height: 36px;
  border-radius: 18px;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  background-color: #d64b4b;
  margin-left: 6.5px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
  display: inline-block;
}
.view-video a {
  color: #fff;
  font-size: 14px;
}
</style>